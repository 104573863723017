<template>
	<div v-if="!loading" class="auth-page">

		<p v-if="!siteAvailability.memberDisabled" name="Member Enabled Alert Site Target" class="alert-site-target">Member Enabled Alert Site Target</p>
		<p v-if="!siteAvailability.nonMemberDisabled" name="NonMember Enabled Alert Site Target" class="alert-site-target">NonMember Enabled Alert Site Target</p>

		<Portal to="hero">
			<StsTitle class="is-desktop" isSmall>
				Welcome back!
				<br />
				Review your loan status here.
			</StsTitle>
		</Portal>

		<StsTitle class="is-mobile" isSmall>
			Welcome back!
			<br />
			Review your loan status here.
		</StsTitle>

		<SessionExpired v-if="showSessionExpired" />

		<ChallengeModal v-if="modalActive" @close="handleModalClosure" />

		<ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="handleSubmit()">
			<StsTabs @select="model => (selectedModel = model)">
				<StsTab
					id="applicant"
					:selected="defaultLogin"
					:name="selectedModel === 'applicant' ? 'I am a Guest' : 'Guest?'"
				>
					<div v-if="showVerificationTab">
						<StsVInput
							v-model="member.applicant.lastName"
							validationRules="required"
							:disabled="submitting"
							label="Last Name"
							type="text"
						/>

						<StsVInput
							v-model="member.applicant.ssn"
							v-if="!hasApplicantId"
							type="tel"
							mask="###-##-####"
							:disabled="submitting"
							validationRules="required|digits:9"
							label="SSN"
						/>

						<StsVInput
							v-model="member.applicant.ssn"
							v-if="hasApplicantId"
							type="tel"
							mask="####"
							:disabled="submitting"
							validationRules="required|digits:4"
							label="Last 4 of SSN"
						/>

						<ReCaptchaV2 v-if="isCaptchaEnabled(2, 1)" :dataReset="captchaV2.reset" />

						<ReCaptchaV3 v-if="isCaptchaEnabled(2, 3)" ref="captcha3" />
					</div>

					<div v-if="channelDisabled" v-html="siteAvailability.nonMemberMessage"></div>
					<div v-if="showUnsupportedTab" v-html="notSupportedMessage"></div>
				</StsTab>

				<StsTab
					id="authenticate"
					:name="`Existing Member${selectedModel === 'authenticate' ? '' : '?'}`"
					:selected="!defaultLogin"
				>
					<div v-if="showAuthenticationTab">
						<StsVInput
							v-model="member.authenticate.userName"
							label="User Name"
							type="text"
							:disabled="submitting"
							validationRules="required"
						/>

						<StsVInput
							v-model="member.authenticate.password"
							label="Password"
							type="password"
							:disabled="submitting"
							validationRules="required"
						/>

						<ReCaptchaV2 v-if="isCaptchaEnabled(1, 1)" :dataReset="captchaV2.reset" />

						<ReCaptchaV3 v-if="isCaptchaEnabled(1, 3)" ref="captcha3" />
					</div>

					<div v-if="channelDisabled" v-html="siteAvailability.memberMessage"></div>
					<div v-if="showUnsupportedTab" v-html="notSupportedMessage"></div>
				</StsTab>
			</StsTabs>

			<section class="button-container">
				<StsButton
					:disabled="invalid || submitting || channelDisabled || captchaNeeded || showUnsupportedTab"
					buttonType="submit"
					title="Start"
				/>
			</section>
		</ValidationObserver>
	</div>
</template>

<script>
import { Portal } from 'portal-vue';
import _cloneDeep from 'lodash.clonedeep';
import { ValidationObserver } from 'vee-validate';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { createToastInterface } from 'vue-toastification';

import { ReCaptchaV2, ReCaptchaV3, SessionExpired } from '@/components';
import { StsButton, StsTab, StsTabs, StsTitle } from '@/components/SwitchThink';
import { StsVInput } from '@/components/SwitchThink/form';
import { AuditProcessingStage, CaptchaAction, CaptchaImplementation } from '@/constants';
import showSpinner from '@/mixins/showSpinner';
import { buildCaptchaResults, toastConfig, getCaptchaUseConfiguration, ruleBuilder } from '@/utils';

const toast = createToastInterface(toastConfig);

ruleBuilder(['required', 'digits']);

export default {
	name: 'Authentication',
	mixins: [showSpinner],
	components: {
		Portal,
		ChallengeModal: () => import('@/views/Partials/ChallengeModal'),
		ReCaptchaV2,
		ReCaptchaV3,
		SessionExpired,
		StsButton,
		StsVInput,
		StsTab,
		StsTabs,
		StsTitle,
		ValidationObserver
	},
	beforeRouteEnter(to, from, next) {
		const devBypass = JSON.parse(to.query.devBypass || false);
		const ev = to.query.ev;
		const sso = to.query.sso ? to.query.sso : to.query.SSO ? to.query.SSO : to.query.sSO ? to.query.sSO : '';
		const filterList = new Set(['devBypass', 'ev', 'sso', 'SSO', 'sSO'])
		const queryKeys = Object.keys(to.query).filter(key => !filterList.has(key));

		if (queryKeys.length) {

            const query = devBypass ? { devBypass } : ev ? { ev } : sso ? { sso } : {};

			return next({ ...to, query });
		}

		return next();
	},
	data() {
		return {
			selectedModel: '',
			member: {
				applicant: {
					lastName: '',
					ssn: '',
					loadUrl: window.location.href
				},
				authenticate: {
					userName: '',
					password: '',
					loadUrl: window.location.href
				},
                sso: {
					sso: '',
					loadUrl: window.location.href
                }
			},
			encryptedValue: '',
			notSupportedMessage:
				"<h2>We're Sorry!</h2> <br /> We're missing some information, please click the link provided in your email to try again.",
			modalActive: false
		};
	},
	computed: {
		...mapGetters(['captchaV2', 'captchaV2Hidden', 'captchaUseConfig', 'captchaConfig']),
		...mapState({
			siteAvailability: state => state.siteAvailability,
			showSessionExpired: state => state.sessionTimer.sessionExpired,
			allowApplicantSearch: state => state.allowApplicantSearch,
			allowAuthenticatedApplicantSearch: state => state.allowAuthenticatedApplicantSearch,
			applicantToken: state => state.applicant.applicantToken
		}),
		...mapState('config', { productType: state => state.productTypeEnum }),
		channelDisabled() {
			if (this.$route.query.devBypass) {
				return false;
			}
			if (this.selectedModel === 'authenticate') {
				return this.siteAvailability.memberDisabled;
			}

			return this.siteAvailability.nonMemberDisabled;
		},
		defaultLogin() {
			return this.$route.query.memberType === 'existing' || this.$route.query.sso !== null;
		},
		captchaNeeded() {
			const authenticationV2Enabled = this.isCaptchaEnabled(
				AuditProcessingStage.AUTHENTICATE,
				CaptchaImplementation.RECAPTCHAV2
			);
			const newApplicantV2Enabled = this.isCaptchaEnabled(
				AuditProcessingStage.NEW_APPLICANT,
				CaptchaImplementation.RECAPTCHAV2
			);

			return (authenticationV2Enabled || newApplicantV2Enabled) && !this.captchaV2.token;
		},
		showVerificationTab() {
			return (this.hasApplicantId || this.allowApplicantSearch) && !this.channelDisabled;
		},
		showAuthenticationTab() {
			return (this.hasApplicantId || this.allowAuthenticatedApplicantSearch) && !this.channelDisabled;
		},
		showUnsupportedTab() {
			return !this.hasApplicantId && !this.allowAuthenticatedApplicantSearch && !this.channelDisabled;
		},
		hasApplicantId() {
			return this.applicantToken ? this.applicantToken !== '00000000-0000-0000-0000-000000000000' : false;
		}
	},
	async created() {
		try {
			this.showSpinner({ submitting: true });

			// Check for single sign-on querystring
			if (this.$route.query.sso || this.$route.query.SSO) {
				this.selectedModel = 'authenticate';
				this.member.sso.sso = this.$route.query.sso ? this.$route.query.sso : this.$route.query.SSO;

				const result = await this.authenticateSSO(this.member.sso);
				this.handleResponse(result);
			} else if (this.$route.query.ev) { // Check for encrypted value querystring
				await this.loadApplicant({
					encryptedValue: this.$route.query.ev.replaceAll('"', '')
				});
			}
		} catch (error) {
			return toast.error(error);
		} finally {
            this.showSpinner({ submitting: false });
        }
	},
	methods: {
		...mapActions('applicant', ['authenticateApplicant', 'verifyApplicant', 'loadApplicant', 'authenticateSSO']),
		...mapActions('challenge', ['createChallenge', 'createChallengeAction']),
		...mapActions(['updateCaptcha', 'updateCaptchaUse']),
		...mapMutations('application', ['setData']),
		async handleSubmit() {
			const isValid = await this.$refs.observer.validate();

			if (!isValid) {
				return toast.error('Form is invalid.');
			}

			try {
				this.showSpinner({ submitting: true });

				const request = this.member[this.selectedModel];

				await this.triggerCaptcha3Request();

				this.populateCaptchaResults(request);

				const result = this.selectedModel === 'applicant'
					? await this.verifyApplicant(request)
					: await this.authenticateApplicant(request);

				this.handleResponse(result);

			} catch (error) {
				return toast.error(error);
			} finally {
                this.showSpinner({ submitting: false });
            }
		},
		handleResponse(result) {

            if (result.captchaAction !== CaptchaAction.NONE) {

                this.processCaptchaAction(result.captchaAction);

            }

            if (!result.isVerified) {
                this.showSpinner({ submitting: false });
                return this.$router.push(this.$constructUrl('InactiveLoan'));

            } else if (!result.isEligibleToUse) {

                //TODO: Redirect to in ineligible page

            } else if (result.requiresMFA) {

                this.modalActive = true;

            } else {

                this.continueApplication();

            }

        },
		async triggerCaptcha3Request() {
			if (
				this.isCaptchaEnabled(AuditProcessingStage.AUTHENTICATE, CaptchaImplementation.RECAPTCHAV3) ||
				this.isCaptchaEnabled(AuditProcessingStage.NEW_APPLICANT, CaptchaImplementation.RECAPTCHAV3)
			) {
				await this.$refs.captcha3.sendAction(`${this.selectedModel}Submit`);
			}

			return true;
		},
		isCaptchaEnabled(processingStage = '', captchaImplementation = '') {
			const useConfig = getCaptchaUseConfiguration(this.captchaUseConfig, processingStage, captchaImplementation);
			const result = useConfig.length > 0 ? useConfig[0] : { enabled: false };

			return result.enabled;
		},
		populateCaptchaResults(request) {
			const auditProcessingStage =
				this.selectedModel === 'applicant'
					? AuditProcessingStage.NEW_APPLICANT
					: AuditProcessingStage.AUTHENTICATE;
			const pageCaptchaConfigs = getCaptchaUseConfiguration(this.captchaUseConfig, auditProcessingStage);

			request.captchaResults = buildCaptchaResults(pageCaptchaConfigs, this.captchaConfig);
		},
		enableForcedCaptchas(processingStage) {
			const configs = getCaptchaUseConfiguration(this.captchaUseConfig, processingStage);

			if (configs) {
				for (const item of configs) {
					const config = _cloneDeep(item);

					if (config.isForcedConfiguration) {
						config.enabled = true;
						this.updateCaptchaUse(config);
					}
				}
			}
		},
		processCaptchaAction(captchaAction) {
			switch (captchaAction) {
				case CaptchaAction.DENY:
					toast.error('There was an error processing your request, please refresh the page and try again.');

					break;
				case CaptchaAction.FORCE_CAPTCHA:
					this.selectedModel === 'applicant'
						? this.enableForcedCaptchas(AuditProcessingStage.NEW_APPLICANT)
						: this.enableForcedCaptchas(AuditProcessingStage.AUTHENTICATE);

					break;
				default:
					this.continueApplication();
					break;
			}
		},
		continueApplication() {
			if (!this.modalActive) {
				return this.$router.push(this.$constructUrl('Application'));
			}
		},
		async handleModalClosure() {
			this.modalActive = false;

			await this.$nextTick();

			this.showSpinner({ submitting: false });
			this.continueApplication();
		}
	}
};
</script>

<style lang="scss" scoped>
.auth-page {
	padding-top: 2.5rem;

	.button-container {
		padding: 1.5rem 1.5rem 0;
		text-align: center;

		::v-deep button {
			max-width: 16.25rem;
			width: 100%;
		}
	}

	.help {
		align-items: center;
		display: flex;
		flex-direction: column;
		padding: 1.5rem 1.5rem 0;

		h3 {
			color: var(--tertiary);
			font-size: 1.2em;
			text-align: center;
		}

		ul {
			color: var(--primary);
			display: grid;
			grid-template-columns: 1fr;
			margin-bottom: 0;
			padding-left: 0;
		}

		@media only screen and (min-width: 850px) {
			ul {
				grid-auto-flow: column;
				grid-template-columns: 1fr 1fr;
				grid-template-rows: 1fr 1fr 1fr;
			}
		}

		@media only screen and (max-width: 849px) {
			ul {
				text-align: center;

				li {
					font-weight: 700;
					list-style-type: none;
				}
			}
		}
	}

	.alert-site-target {
		display: none;
	}

}
</style>